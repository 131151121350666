import React from 'react';
import { SensorCard } from '../components/features/sensors/SensorCard';
import { useSensorData } from '../hooks/useSensorData';
import { SensorVisualization } from '../components/features/sensors/SensorVisualization';

export const Dashboard = () => {
  const { sensors, selectedSensor, setSelectedSensor } = useSensorData();

  return (
    <div>
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {sensors.map((sensor) => (
          <SensorCard
            key={sensor.id}
            sensor={sensor}
            onViewDetails={() => setSelectedSensor(sensor)}
          />
        ))}
      </div>
      {selectedSensor && (
        <SensorVisualization
          sensor={selectedSensor}
          onClose={() => setSelectedSensor(null)}
        />
      )}
    </div>
  );
};
