import { Thermometer, Waves } from 'lucide-react';
import { generateCurrentData } from '../lib/generators/sensorData';

export const SENSORS = [
  {
    id: 1,
    name: 'Temperature Sensor',
    description: 'Temperature and Humidity Sensor Located in Room 1',
    icon: Thermometer,
    type: 'temperature',
    data: [
      { time: '00:00', value: 21 },
      { time: '01:00', value: 22 },
      { time: '02:00', value: 23 },
      { time: '03:00', value: 24 },
      { time: '04:00', value: 23 },
      { time: '05:00', value: 22 },
    ]
  },
  {
    id: 2,
    name: 'Current Sensor',
    description: 'Current Sensor Located in Ocean Station A',
    icon: Waves,
    type: 'current',
    data: generateCurrentData()
  }
];
