export const generateCurrentData = () => {
  const data = [];
  let time = new Date('2024-01-25T21:00:00');
  let baseVelocity = 0.5;
  let baseDirection = 180;
  
  for (let i = 0; i < 100; i++) {
    const velocityNoise = (Math.random() - 0.5) * 0.2;
    const directionNoise = (Math.random() - 0.5) * 20;
    data.push({
      time: time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
      velocity: Math.max(0, Math.min(6, baseVelocity + velocityNoise)),
      direction: (baseDirection + directionNoise + 360) % 360
    });
    time.setMinutes(time.getMinutes() + 15);
    
    baseVelocity += (Math.random() - 0.5) * 0.1;
    baseDirection += (Math.random() - 0.5) * 5;
  }
  return data;
};
