import React from 'react';

export const Data = () => (
  <div>
    <h2 className="text-2xl font-semibold text-gray-800 mb-6">Data Management</h2>
    <div className="bg-white rounded-lg shadow-md p-6">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">Data Storage</h3>
      <div className="space-y-4">
        <div className="p-4 border rounded-lg">
          <p className="font-medium">Temperature Records</p>
          <p className="text-sm text-gray-600">Total Records: 1,234</p>
          <p className="text-sm text-gray-600">Storage Used: 2.3 MB</p>
        </div>
        <div className="p-4 border rounded-lg">
          <p className="font-medium">Current Data</p>
          <p className="text-sm text-gray-600">Total Records: 5,678</p>
          <p className="text-sm text-gray-600">Storage Used: 4.1 MB</p>
        </div>
      </div>
    </div>
  </div>
);
