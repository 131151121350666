import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { X } from 'lucide-react';

const DirectionArrow = ({ cx, cy, angle }) => {
  const arrowLength = 15;
  const x2 = cx + arrowLength * Math.sin((angle * Math.PI) / 180);
  const y2 = cy - arrowLength * Math.cos((angle * Math.PI) / 180);

  return (
    <line
      x1={cx}
      y1={cy}
      x2={x2}
      y2={y2}
      stroke="blue"
      strokeWidth={2}
      markerEnd="url(#arrowhead)"
    />
  );
};

const CustomizedAxisTick = ({ x, y, payload, data }) => {
  const index = payload.index;
  if (index % 8 !== 0 || !data[index]) return null;

  const direction = data[index].direction;

  return (
    <g transform={`translate(${x},${y + 20})`}>
      <DirectionArrow cx={0} cy={0} angle={direction} />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-gray-300 p-2 rounded shadow">
        <p className="label">{`Time: ${label}`}</p>
        {payload[0].payload.velocity !== undefined && (
          <p className="velocity">{`Velocity: ${payload[0].value.toFixed(2)} kt`}</p>
        )}
        {payload[0].payload.direction !== undefined && (
          <p className="direction">{`Direction: ${payload[0].payload.direction.toFixed(1)}°`}</p>
        )}
        {payload[0].value !== undefined && payload[0].payload.velocity === undefined && (
          <p className="value">{`Value: ${payload[0].value}`}</p>
        )}
      </div>
    );
  }
  return null;
};

export const SensorVisualization = ({ sensor, onClose }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  const renderChart = () => {
    if (sensor.type === 'current') {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={sensor.data} margin={{ top: 5, right: 30, left: 20, bottom: 70 }}>
            <defs>
              <marker
                id="arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="0"
                refY="3.5"
                orient="auto"
              >
                <polygon points="0 0, 10 3.5, 0 7" fill="blue" />
              </marker>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="time" 
              height={60} 
              tick={<CustomizedAxisTick data={sensor.data} />} 
              interval={0}
            />
            <YAxis 
              yAxisId="left" 
              label={{ value: 'Velocity (kt)', angle: -90, position: 'insideLeft' }} 
              domain={[0, 6]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line 
              yAxisId="left" 
              type="monotone" 
              dataKey="velocity" 
              stroke="#ff0000" 
              dot={{ stroke: '#ff0000', strokeWidth: 2 }} 
              activeDot={{ r: 8 }}
              name="Current - Velocity" 
            />
          </LineChart>
        </ResponsiveContainer>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={sensor.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" name={sensor.name} />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full ${isFullScreen ? 'z-50' : 'z-30'}`}>
      <div className={`relative top-20 mx-auto p-5 border w-11/12 shadow-lg rounded-md bg-white ${isFullScreen ? 'h-5/6' : 'max-w-4xl'}`}>
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{sensor.name}</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">{sensor.description}</p>
          </div>
          <div className="mt-4" style={{ height: isFullScreen ? 'calc(100vh - 250px)' : '400px' }}>
            {renderChart()}
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={toggleFullScreen}
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              {isFullScreen ? 'Exit Full Screen' : 'Open in Full Page'}
            </button>
          </div>
        </div>
        <button onClick={onClose} className="absolute top-0 right-0 mt-4 mr-4">
          <X className="h-6 w-6 text-gray-500" />
        </button>
      </div>
    </div>
  );
};
