import React from 'react';

export const Devices = () => (
  <div>
    <h2 className="text-2xl font-semibold text-gray-800 mb-6">Devices</h2>
    <div className="bg-white rounded-lg shadow-md p-6">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">Connected Devices</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="border rounded-lg p-4">
          <p className="font-medium">Temperature Sensor</p>
          <p className="text-sm text-gray-600">Status: Online</p>
          <p className="text-sm text-gray-600">Last Updated: 2 mins ago</p>
        </div>
        <div className="border rounded-lg p-4">
          <p className="font-medium">Current Sensor</p>
          <p className="text-sm text-gray-600">Status: Online</p>
          <p className="text-sm text-gray-600">Last Updated: 1 min ago</p>
        </div>
      </div>
    </div>
  </div>
);
