import React, { useState } from 'react';
import { MainLayout } from './components/common/Layout/MainLayout';
import { Dashboard } from './pages/Dashboard';
import { Devices } from './pages/Devices';
import { Data } from './pages/Data';
import { Settings } from './pages/Settings';
import { SensorVisualization } from './components/features/sensors/SensorVisualization';
import { useSensorData } from './hooks/useSensorData';

const App = () => {
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { selectedSensor, setSelectedSensor } = useSensorData();

  const sidebarProps = {
    isOpen: isSidebarOpen,
    setCurrentPage,
    currentPage,
    toggleSidebar: () => setIsSidebarOpen(!isSidebarOpen),
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'dashboard':
        return <Dashboard />;
      case 'devices':
        return <Devices />;
      case 'data':
        return <Data />;
      case 'settings':
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <MainLayout sidebarProps={sidebarProps}>
      {renderPage()}
      {selectedSensor && (
        <SensorVisualization
          sensor={selectedSensor}
          onClose={() => setSelectedSensor(null)}
        />
      )}
    </MainLayout>
  );
};

export default App;
