import { useState } from 'react';
import { SENSORS } from '@/constants/sensorData';

export const useSensorData = () => {
  const [selectedSensor, setSelectedSensor] = useState(null);

  return {
    sensors: SENSORS,
    selectedSensor,
    setSelectedSensor,
  };
};
