import React from 'react';
import { Sidebar } from './Sidebar';

export const MainLayout = ({ children, sidebarProps }) => (
  <div className='flex h-screen overflow-hidden'>
    <Sidebar {...sidebarProps} />
    <div className='flex-1 flex flex-col overflow-hidden transition-all duration-300 ease-in-out'>
      <main className='flex-1 overflow-x-hidden overflow-y-auto bg-blue-100'>
        <div className='container mx-auto px-6 py-8'>
          {children}
        </div>
      </main>
    </div>
  </div>
);

