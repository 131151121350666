import React from 'react';

export const SensorCard = ({ sensor, onViewDetails }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h3 className="text-lg font-semibold text-gray-700 mb-4">{sensor.name}</h3>
    <sensor.icon className="w-16 h-16 text-blue-500 mb-4" />
    <p className="text-gray-600 mb-4">{sensor.description}</p>
    <button
      onClick={() => onViewDetails(sensor)}
      className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
    >
      View Details
    </button>
  </div>
);
