import React from 'react';

export const Settings = () => (
  <div>
    <h2 className="text-2xl font-semibold text-gray-800 mb-6">Settings</h2>
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-4">General Settings</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Notifications</p>
                <p className="text-sm text-gray-600">Receive alerts and updates</p>
              </div>
              <button className="px-4 py-2 bg-blue-500 text-white rounded">
                Configure
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Data Retention</p>
                <p className="text-sm text-gray-600">Manage data storage period</p>
              </div>
              <button className="px-4 py-2 bg-blue-500 text-white rounded">
                Configure
              </button>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-4">System Information</h3>
          <div className="space-y-2">
            <p className="text-sm">Version: 1.0.0</p>
            <p className="text-sm">Last Updated: January 13, 2024</p>
            <p className="text-sm">Storage: 45% used</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
